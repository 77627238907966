/// <reference lib="dom" />
/// <reference lib="dom.iterable" />

// Note:
// this needs to be set, otherwise once the app gets bundled the "global" property will be undefined
// - issues we may encounter → handlebars not rendering the output content on build or toggle exam groups not working on dev
window.global ||= window;

// Note:
// this needs to be set because RxDB assumes a Node.js-specific global variable that is not added to browser runtimes by some bundlers
(window as any).process = {
  env: { DEBUG: undefined },
};

// Note:
// - DataStream is required by wl-msg-reader which is used by the react-doc-viewer
// - Unfortunately, the declaration of the global in wl-msg-reader is not done correctly, so we need to declare it at least. Currently using null.
if (!("DataStream" in window)) {
  // @ts-expect-error quick fix based on what I've seen in the following issue: https://github.com/Alcumus/react-doc-viewer/issues/91
  window.DataStream = null;
}
